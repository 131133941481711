import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";
import { innerMiddleMixin } from "@/widgets/Container/Templates/mixin";

const RelatedContainer = styled.div`
  position: relative;
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color: ${ColorDark.greySeparator};
      }
    }
    
  } 
  ${breakpoints.phone} {
padding-bottom: 2.5rem;
  }
  span {
    color: ${Color.black};
    font-size: 20px;
    font-weight: 700;
    font-family: ${Font.sansRegular};
    padding: 0.6rem 0;
  }
  .inner-card-m{
    min-height: 90px;
    padding: 0.5rem 0;

  }

  .related-content {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    column-gap: 0.5rem;
    justify-content: left;
    min-height: 90px;

    img {
      object-fit: cover;
      max-width: 90px;
      max-height: 90px;
      ${breakpoints.phone} {
        max-width: 100px;
      max-height: 100px;
      }
    }
    h3 {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      color: ${Color.black000};
      ${Font.sansSemiBold};
      display: -webkit-box;
      overflow: hidden;
      align-self: start;
      line-height: 22px;
      font-size: 17px;
      ${breakpoints.darkMode} {

        [data-theme='dark'] & {
        color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        line-height: 21px;
        font-size: 17px;
        letter-spacing: -0.17px;
      }
    }
    .text{
      padding: 0;
    }
   
  
  }
  .body-title{
    display: none;
  }

  .separator-title{
    hr {
    margin:0;
    border: 0 none;
    &:before {
      content: "";
      height: 1px;
      width: 100%;
      background: #d1d1d1;
      display: block;
      margin: 3px 0;
      ${breakpoints.darkMode} {

        [data-theme='dark'] & {
        background: ${ColorDark.greySeparator};
        }
      }
    }
    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: #d1d1d1;
      display: block;
      margin-top: 5px;
      ${breakpoints.darkMode} {

        [data-theme='dark'] & {
        background: ${ColorDark.greySeparator};
        }
      }
    }
  }
  .sep{
      font-size: 16px;
      line-height: 28px;
      ${breakpoints.phone} {
        font-size: 17px;
      }
    }
  }
`;
export default RelatedContainer;